import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import BmPage from "src/components/bmPage"
import Btf from "src/components/btf"
import Disclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const BmData = import("./data/data.json");
const btfData = import("./data/btfData.json");

const footer = <RawFooter></RawFooter>

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Craft Breathtaking AI Wallpapers in Seconds! | flairwallpapers.com</title>
    <style type="text/css">{`
    #bmpage-module--bmPage p {
	font-size: 12px;
	text-align: center;
	width: 700px;
	color: #666;
	line-height: 150%;
}
#bmpage-module--bmPage p a {
	color: #666;
	font-style: none;
}
#disclaimers-module--disclaimers p {
	font-size: 12px;
	text-align: center;
	width: 700px;
	color: #666;
	line-height: 150%;
}
#disclaimers-module--disclaimers p a {
	color: #666;
	font-style: none;
}
footer {
	position: relative;
	width: 100%;
	bottom: 2px;
	padding: 0;
	margin: 0;
	z-index: 1;
}
footer ul {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	color: #666;
	font-size: 12px;
	padding: 10px 0;
	list-style: none;
}
footer ul li {
	margin: 0;
}
footer ul li a {
	text-decoration: none;
	color: #666;
}
footer ul li:after {
	content: '|';
	padding: 0 4px;
}
footer ul li:last-child:after {
	content: none;
	padding: 0;
}
@media screen and (max-width: 1366px) {
	#bmpage-module--bmPage p, #disclaimers-module--disclaimers p {
  width:520px;
    }
}
    `}
    </style>
    </Helmet>
    <section>
      <BmPage data={BmData}><Disclosure></Disclosure></BmPage>
      </section>
      <Btf data={btfData} footer={footer}><Disclosure language='en-mbc-edge'></Disclosure></Btf>
    </HomepageLayout>

  )
  }
